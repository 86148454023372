<template>
  <section class="resumeH fade-in" v-bind="initSocketEvents">
    <div class="resumeH__wrapper" v-if="$userData.role !== 'model'">
      <div
        v-for="(statu, inx) in status.slice(1)"
        :class="['resumeH__router', $route.query.status === statu.value ? 'resumeH__router-active' : '']"
        :key="inx"
        @click="(page = 0), setFilter('reset'), setFilter({ srcElement: { name: 'status', value: statu.value } }), getCasesbyQuery()"
      >
        {{ statu.name }}
      </div>
    </div>
    <div class="resumeH__filter ">
      <filterUsing :area="area" :changeValue="getCasesbyQuery"></filterUsing>
    </div>
    <div class="resumeH__content">
      <router-link  class="resumeH__cardCase"   v-for="(dat, key) in cases" :key="key" :to="{name: 'detailsCase', query: { ...$route.query, id: dat._id } }" >
        <cardCases
          :requirements="typesrequirements"
          :area="area"
          class="gcard resumeH__wrapper"          
          :case="dat"         
        />
      </router-link>
      <h5 v-show="!(!!cases ? cases.length : '')">
        {{ !isLoading ? "Sin tickets por el momento o Cambia el filtro" : "" }}
      </h5>
    </div>
    <div class="spinner" v-if="isLoading" />
  </section>
</template>

<script>
import mixinCrud from "./mixinCrud";
import cardCases from "./cardCases";
import filterUsing from "./filterUsing.vue";
import { socket } from "@/socket";
export default {
  name: "resumeCases",
  mixins: [mixinCrud],
  components: {
    cardCases,
    filterUsing,
  },
  data() {
    return {
      page: 0,
      isLoading: true,
      cases: [],
      area: "",
      typesrequirements: "",
      scrollPosition: 0
    };
  },
  computed: {
    initSocketEvents() {
      if (this.$socketState) {
        socket.on("newMessageHelpDesk", (data) => {
          const caseOfMessage = this.cases.find((item) => item._id === data.idRelation);

          if (this.$userData._id === caseOfMessage.whoCreate._id && this.$userData._id !== data.idUser._id) {
            caseOfMessage.unreadMsg++;
          }
          if (this.$userData._id !== caseOfMessage.whoCreate._id && caseOfMessage.whoCreate._id === data.idUser._id) {
            caseOfMessage.unreadMsg++;
          }
        });
      }
    },
  },
  watch: {
    $route() {
      if (this.$route.query.myCases) {
        this.getCasesbyQuery();
      }
    },
  },
  methods: {
    onScroll() {
      const helpdesk = document.querySelector('.helpdesk')
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = helpdesk.offsetHeight;

      if (scrollTop + windowHeight >= documentHeight - 150) {
        this.casePagination();
      }
    },
    async casePagination() {
      if (this.isLoading || !this.hasMore) {
        return;
      };
      
      this.isLoading = true;
      this.page++;
      const all = await this.getCases(this.setQuery() + "&page=" + this.page, true);
      this.cases.push(...all) 
      this.isLoading = false;
    },
    async getCasesbyQuery() {
      this.isLoading = true;
      this.cases = [];
      this.page = 0;
      await this.getCases(this.setQuery() + "&page=" + this.page);
      this.isLoading = false;
    },
  },
  async beforeMount() {
    if (!this.$route.query.status) this.setFilter({ srcElement: { name: "status", value: "all" } });
    await this.getCasesbyQuery();
    await this.getInfoCases("area", "area");
    await this.getInfoCases("requirement", "typesrequirements");
    this.isLoading = false;
  },
   mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("scroll", this.onScroll);
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    this.scrollPosition = scrollPosition
    next(); 
  },
  activated() {
    window.addEventListener("scroll", this.onScroll);

    if (this.scrollPosition) {
      window.scrollTo(0, parseInt(this.scrollPosition, 10)); 
    }
  },
  deactivated() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style lang="scss">
.resumeH {
  &__wrapperDetails{
    position: fixed;
    top: 10;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    z-index: 100;
  }
  &__content {
    margin-top: 15px;
  }
  &__filter {
    margin: 0 0 15px 0;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
  }
  &__router {
    padding: 5px;
    height: 36px;
    cursor: pointer;
    white-space: nowrap;
    width: 150%;
    border: #d8d8d8 solid 1px;
    background-color: white;
    color: black;
    text-align: center;
    border-radius: 9px 9px 0px 0px;
    &-active {
      background-color: #bd0909;
      color: white;
    }
  }
  &__cardCase{
    text-decoration: none;
    color: #212529; 
    &:hover {
      text-decoration: none; 
      color: #212529; 
    }
  }
}
</style>
