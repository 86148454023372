<template>
  <section class="caseCard" v-if="showCard" :style="`--area-color:${currentArea.color}`">
    <div class="caseCard__container">
      <div class="caseCard__wrapper">
        <div class="caseCard__content">
          <div class="caseCard__content" v-if="!mycase">
            <div class="caseCard__avatar">
              <avatar class="caseCard__avatar" :user="cases.whoCreate"></avatar>
            </div>
            <div class="caseCard__info">
              <p class="caseCard__userName">{{ cases.whoCreate.user }}{{ titleCard() }}</p>
              <p class="pq">Fecha de solicitud: {{ $global.dateLocaleFormat(cases.date, options) }}</p>
            </div>
          </div>
          <div :class="['caseCard__content', 'caseCard__ticketNumber', cases.unreadMsg && 'caseCard__ticketNumber-unreadMsg']">
            <iconic class="caseCard__ticketNumber-iconic" name="ticket"></iconic>
            <p class="caseCard__ticketNumber-text">
              {{ "#" + ticketNumber() }}
            </p>
          </div>
        </div>
      </div>
      <div class="caseCard__total">
        <div class="caseCard__titleCase">{{ typeRequirements() }} {{ cases.title ? "  :  " + cases.title : "" }}</div>
        <p class="caseCard__info__description">{{ cases.description }}</p>
      </div>
      <div class="caseCard__options">
        <div class="caseCard__options__container" v-if="$userData.role === 'superadmin' || $userData.role === 'coordinator'">
          <div class="caseCard__wrapper2" v-if="cases.status !== 'close'">
            <div :class="`ticket-area-select`" @click.prevent>
              <AreaSelect :areas="area" :value="currentArea" @change="areaSelected" />
            </div>
            <select class="caseCard__wrapper2-select ginput" v-model="prioritys" name="priority" @change="update('priority', 'prioritys')" @click.prevent>
              <option :value="statu.value" v-for="(statu, idx) in priority" :key="idx">{{ statu.name }}</option>
            </select>
          </div>
        </div>
        <div class="caseCard__wrapper1">
          <div class="caseCard__wrapper2-number">
            <div :class="['caseCard__content', 'caseCard__ticketNumber', cases.unreadMsg && 'caseCard__ticketNumber-unReadAlert']" v-if="cases.unreadMsg">
              <span>{{ cases.unreadMsg + " Mensajes" }}</span>
            </div>
          </div>
          <div v-if="($userData.role === 'superadmin' || $userData.role === 'coordinator') && !mycase" :color="priorityValue" class="caseCard__options-priority">
            {{ priorityValue }}
          </div>
          <div v-if="mycase" :status="statusCase" class="caseCard__options-priority">
            {{ statusCase }}
          </div>
          <div v-if="!mycase && $userData.role !== 'superadmin' && $userData.role !== 'coordinator'" :status="statusCase" class="caseCard__options-priority">
            {{ statusCase }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import mixinCrud from "./mixinCrud";
  export default {
    name: "caseCard",
    mixins: [mixinCrud],
    components: {
      AreaSelect: () => import("./AreaSelect.vue"),
    },
    props: {
      case: {
        type: Array[Object],
        required: true,
      },
      area: {
        type: Array[Object],
      },
      requirements: {
        type: Array[Object],
      },
    },
    data() {
      return {
        statuss: "await",
        cases: this.case,
        selectarea: "",
        prioritys: "",
        showCard: true,
        mycase: false,
        options: { month: "2-digit", day: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", hourCycle: "h12" },
      };
    },
    computed: {
      priorityValue() {
        if (this.prioritys === "low") return "Baja";
        if (this.prioritys === "medium") return "Media";
        if (this.prioritys === "urgent") return "Urgente";
        return "Baja";
      },
      statusCase() {
        if (this.statuss === "await") return "En espera";
        if (this.statuss === "process") return "En proceso";
        return "Cerrado";
      },
      currentArea: function() {
        const areas = this.area || [];
        const current = areas.find((area) => area._id === this.selectarea);
        return current || {};
      },
    },
    methods: {
      titleCard() {
        if (this.cases.whoCreate.city && this.cases.whoCreate.city !== "null" && this.cases.whoCreate.city !== null) {
          let stringName = " " + this.cases.whoCreate.city;
          if (this.cases.whoCreate.office && this.cases.whoCreate.office !== "null" && this.cases.whoCreate.office !== null) stringName += "-" + this.cases.whoCreate.office || "";

          return stringName || "";
        }
      },
      update(context, value) {
        const toUpdate = {
          [context]: this[value],
        };
        if (toUpdate[context] !== "") this.updateCase(toUpdate, this.cases._id);
        if (this.$route.query.status === "process" && value === "selectarea") {
          this.showCard = false;
        }
      },
      areaSelected: function(area) {
        this.selectarea = area._id;
        this.update("area", "selectarea");
      },
      typeRequirements() {
        let [data, letra] = [this.requirements];
        if (data)
          [letra] = data?.filter((requirement) => {
            if (requirement?._id === this.cases?.typeRequirement) return requirement?.name;
          });
        return this.cases?.otherDetail ? "Otro: " + this.cases?.otherDetail : letra?.name || "";
      },
    },
    beforeMount() {
      this.selectarea = this.cases?.area;
      this.statuss = this.cases?.status || "";
      this.prioritys = this.cases?.priority || "";
      if (this.$userData.user === this.cases.whoCreate.user) this.mycase = true;
    },
  };
</script>
<style lang="scss">
  .caseCard {
    border-left: solid 4px var(--area-color);
    .iconic {
      cursor: pointer;
    }
    &__total {
      width: 100%;
      margin: $mpadding 0 0 0;
    }
    .pq {
      font-size: 12px;
      color: #8c8c8c;
    }
    [disabled] {
      display: block;
    }
    &:hover {
      background-color: #f5f5f5;
    }
    font-family: $first_font;
    width: 100%;
    &__content {
      display: flex;
      justify-content: space-between;
    }
    &__wrapper {
      width: 100%;
    }
    &__wrapper1 {
      @include Flex(inherit, flex-end, center);
      flex-wrap: wrap;
      width: 100%;
      margin-top: 6px;
    }
    &__wrapper2 {
      @include Flex(inherit, flex-start, center);
      gap: $mpadding;
      width: 100%;
      margin-top: 6px;
      flex-wrap: wrap;
      &-ml {
        position: relative;
        @include Flex(inherit, center, center);
      }
      &-select {
        max-width: 160px;
        padding: $mpadding/2 $mpadding;
        border: none;
        background-color: transparent;
        cursor: pointer;
        outline: none;
        text-transform: capitalize;
      }
      &-select,
      .v-select {
        background-color: #fff;
      }
      &-select,
      .vs__dropdown-toggle {
        border: var(--vs-border-width) var(--vs-border-style) var(--vs-border-color);
      }
      &-number {
        display: flex;
        margin-right: 10px;
        white-space: nowrap;
        height: 100%;
        align-items: center;
        gap: $mpadding/2;
        iconic {
          color: #cfcfcf;
        }
      }
      &-unReadMsg {
        position: absolute;
        top: -12px;
        right: -12px;
      }
      &-unReadMsg,
      &-files {
        @include Flex(row);
        width: 18px;
        height: 18px;
        background: $chicago;
        border-radius: 50%;
      }
    }
    &__twoMessages {
      color: $primary-color;
    }
    &__numberUnread {
      font-size: 10px;
      font-weight: 600;
      color: white;
    }
    &__options {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-priority {
        white-space: nowrap;
        border-radius: 5px;
        padding: 2px 5px;
      }
      &__container {
        display: flex;
        align-items: center;
        width: 100%;
        &-item {
          margin-top: 15px;
        }
      }
    }
    &__ticketNumber {
      align-items: center;
      padding: 0 10px;
      &-iconic {
        margin-right: 5px;
      }
      &-text {
        font-family: $sec_font;
        font-size: 1.2em;
        margin: 0;
      }
      &-unreadMsg {
        border-radius: 4px;
        background-color: rgb(240, 234, 234);
        color: $primary-color;
      }
      &-unReadAlert {
        height: 30px;
        font-weight: 600;
        border-radius: 6px;
        animation: skeleton-loading 1s linear infinite alternate;
      }
    }

    &__info {
      &__description {
        max-height: 25px;
        max-width: 70%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      margin-left: 7px;
      margin-top: -10px;
      &-gray {
        color: #868686;
      }
      &-black {
        color: #000;
      }
      &-red {
        color: $primary-color;
      }
    }
    &__container {
      width: 100%;
      margin: 5px 0;
    }
    &__avatar {
      width: 38px;
      height: 38px;
    }
    &__userName {
      margin-top: 5px;
      font-weight: normal;
      text-transform: capitalize;
      font-size: 120%;
    }
    &__titleCase {
      font-family: $sec_font;
      font-size: 120%;
      text-transform: capitalize;
    }
    animation: fadeOut 1s ease-in-out;
    -webkit-animation: fadeIn ease 1.5s;
    @keyframes fadeOut {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0.1;
      }
    }
    [status] {
      cursor: pointer;
    }
    [color="Baja"] {
      border: #e7a601 solid 2px;
      background: rgba(231, 166, 1, 0.06);
      color: #e7a601;
    }
    [color="Media"] {
      border: #2778c4 solid 2px;
      background: rgba(39, 120, 196, 0.13);
      color: #2778c4;
    }
    [color="Urgente"] {
      border: #bd0909 solid 2px;
      background: rgba(189, 9, 9, 0.05);
      color: #bd0909;
    }
    [status="En espera"] {
      border: #e7a601 solid 2px;
      background: rgba(231, 166, 1, 0.06);
      color: #e7a601;
    }
    [status="En proceso"] {
      border: #0fa90c solid 2px;
      background: rgba(189, 9, 9, 0.05);
      color: #0fa90c;
    }
    [status="Cerrado"] {
      border: #5b5957 solid 2px;
      background: rgba(189, 9, 9, 0.05);
      color: #5b5957;
    }
  }
  .skeleton {
    animation: skeleton-loading 5s linear infinite alternate;
  }
  @keyframes skeleton-loading {
    0%,
    50% {
      background-color: $primary-color;
      color: #f5f5f5;
    }
    100% {
      background-color: #f5f5f5;
      color: #fff;
    }
  }
</style>
