<template>
  <section class="filter">
    <div class="filter__wrapper gcard">
      <div class="filter__filter">
        <div class="filter__input filter__inputs-calendar ">
          <input id="date" type="date" class="filter__inputs-select" name="date" v-model="date" @input="setValue" />
        </div>
        <div class="filter__inputs">
          <div :class="`filter__inputs-select v_select`" :style="`--current-color:${currentArea.color}`">
            <iconic class="filter__inputs__iconic" name="filter"></iconic>
            <AreaSelect :areas="area" :value="currentArea" @change="areaSelected" />
          </div>
        </div>
        <div class="filter__inputs " v-if="$userData.role !== 'model'">
          <iconic class="filter__inputs__iconic" name="filter"></iconic>
          <select class="filter__inputs-select" name="priority" v-model="selectpriority" @change="setValue">
            <option :value="are.value" v-for="(are, idx) in priority" :key="idx">
              {{ are.name }}
            </option>
          </select>
        </div>
        <div class="filter__inputs " v-if="notAdmin()">
          <iconic class="filter__inputs__iconic" name="filter"></iconic>
          <select class="filter__inputs-select" name="status" v-model="selectstatus" @change="setValue">
            <option :value="are.value" v-for="(are, idx) in status" :key="idx">
              {{ are.name }}
            </option>
          </select>
        </div>
        <div class="filter__inputs-noColor ginput">
          <iconic name="search"></iconic>
          <input type="text" placeholder="Busqueda" name="search" v-model="search" @input="setValue" class="filter__input ginput" />
        </div>
        <div class="filter__inputs-sort">
          <button
            type="number"
            placeholder="Numero ticket"
            name="sort"
            class="filter__inputs-sort-button"
            @click="(selectsort = !selectsort), setValue({ srcElement: { name: 'sort', value: selectsort ? 'asc' : 'desc' } })"
          >
            <iconic :name="selectsort ? 'arrowUp' : 'arrowDown'" v-if="true"></iconic>
            {{ selectsort ? "ASCENDENTE" : "DESCENDENTE" }}
          </button>
        </div>
        <div class="filter__inputs-clean" v-if="isFilterNow" @click="cleanFilter('reset')">
          <i class="fa fa-times filter__inputs-icon" aria-hidden="true"></i>
          Limpiar Filtros
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SelectInputVue from "../widgets/SelectInput.vue";
import mixinCrud from "./mixinCrud";
import roles from "@/mixins/roles";
export default {
  name: "filters",
  mixins: [mixinCrud, roles],
  props: ["area", "changeValue"],
  components: {
    SelectInputVue,
    AreaSelect: () => import("./AreaSelect.vue"),
  },
  data() {
    return {
      date: "",
      selectarea: "",
      selectpriority: "",
      selectasignament: "",
      selectstatus: "",
      selectsort: true,
      timer: "",
      search: "",
    };
  },
  computed: {
    isFilterNow() {
      return this.selectarea || this.selectpriority || !this.selectsort || this.search || this.selectasignament || this.date;
    },
    currentArea: function() {
      const areas = this.area || [];
      const current = areas.find((area) => area._id === this.selectarea);
      return current || { name: "Area" };
    },
  },
  methods: {
    notAdmin() {
      return this.$userData.role === "model";
    },
    setValue(event) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.setFilter(event);
        this.changeValue();
      }, 200);
    },
    areaSelected: function(area) {
      this.selectarea = area._id;
      this.setValue({ srcElement: { name: "area", value: this.selectarea } });
    },
    cleanFilter() {
      const route = this.$route.query.status;
      this.selectarea = "";
      this.selectpriority = "";
      this.selectstatus = "";
      this.selectasignament = "";
      this.selectsort = true;
      this.search = "";
      this.date = "";
      this.setFilter("reset");
      this.setFilter({ srcElement: { name: "status", value: route || "await" } });
      this.selectstatus = route || "await";
      this.changeValue();
    },
  },
  async beforeMount() {
    Object.keys(this.$route.query).forEach((key) => {
      this["select" + key] = this.$route.query[key];
    });
    this.search = this.$route.query?.search || "";
  },
};
</script>

<style lang="scss">
.filter {
  .gcard {
    padding: 7px 15px;
  }
  width: 100%;
  height: auto;
  position: relative;
  &__wrapper {
    width: 100%;
  }
  &__filter {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    grid-gap: 25px;
    width: 100%;
  }
  &__input {
    border: none;
  }
  &__inputs {
    position: relative;
    width: 100%;
    margin: $mpadding/3;
    &__iconic {
      position: absolute;
      top: 9.5px;
      left: 10px;
      z-index: 1;
    }

    &-calendar {
      margin-top: 5px;
    }
    &-select {
      text-transform: capitalize;
      appearance: none;
      text-align: start;
      outline: none;
      width: 100%;
      height: 33px;
      padding: 4px 8px 4px $mpadding * 2;
      color: $black;
      background-color: #f0f0f0;
      border-radius: 4px;
      border: none;
      box-shadow: 0px 0.5px 2px 0px #00000069;
      position: relative;
      display: flex;
      align-items: center;
      &:hover {
        background-color: rgb(227, 224, 224);
      }
      &.v_select {
        padding: 0;
        .iconic {
          color: var(--current-color);
        }
      }
      .vs__selected,
      .vs__search,
      .vs__dropdown-toggle {
        margin: 0;
        padding: 0;
        border: none;
      }
      .vs__dropdown-toggle {
        padding: 4px 8px 4px 30px;
      }
      .vs__dropdown-menu {
        top: calc(100% - var(--vs-border-width) + #{$mpadding/2});
      }
    }
    &-clean {
      display: flex;
      padding: 5px;
      margin-top: 5px;
      text-align: center;
      white-space: nowrap;
      color: $primary-color;
      cursor: pointer;
      border: none;
    }
    &-sort {
      display: flex;
      padding: 5px;
      cursor: pointer;
      border: none;
      background: none;
      &-button {
        background: transparent;
        padding: 5px;
        margin-left: 5px;
        text-align: center;
        white-space: nowrap;
        color: $primary-color;
        cursor: pointer;
        border: none;
      }
    }
    &-icon {
      margin-top: 5px;
      margin-right: 5px;
    }
    &-noColor {
      margin-top: 5px;
      display: flex;
      height: 35px;
      background-color: transparent;
      border: rgb(186, 186, 186) solid 1px;
      &-icon {
        margin-top: 5px;
        margin-right: 5px;
      }
    }
  }
  .iconic {
    margin: auto;
    margin-right: 5px;
  }
}
</style>
